// src/components/Layout.jsx
import { Link, Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="">
      {/* Header */}
      <header className="bg-[rgba(33,96,100,0.83)] shadow-md">
        <div className="container mx-auto flex items-center justify-between px-4 py-2">
          <Link to="/" className="text-white text-3xl font-bold">
            You&Me
          </Link>

          <svg width="120" height="50" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="40"
              cy="25"
              r="20"
              stroke="#2e3192"
              strokeWidth="6"
              fill="none"
            />

            <circle
              cx="65"
              cy="25"
              r="20"
              stroke="#ec008c"
              strokeWidth="6"
              fill="none"
            />
          </svg>
        </div>
      </header>

      {/* Main content where child routes will be rendered */}
      <main className="flex-grow  mx-auto ">
        <Outlet /> {/* Renders child routes */}
      </main>
    </div>
  );
};

export default Layout;
