import { Link } from "react-router-dom";

export default function Home() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#205F63] text-center grow-1">
      {/* Logo and Title */}
      <div className="flex flex-col items-center bg-[#14616669] p-10 w-[300px] md:w-[400px] h-[300px] md:h-[400px] rounded-full younme-shadow mt-4">
        <div className="relative mb-1">
          <svg width="120" height="100" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="50"
              cy="60"
              r="30"
              stroke="#2e3192"
              strokeWidth="8"
              fill="none"
            />
            <circle
              cx="85"
              cy="60"
              r="30"
              stroke="#ec008c"
              strokeWidth="8"
              fill="none"
            />
          </svg>
        </div>
        <h1
          className="text-white text-3xl md:text-4xl font-bold inter-regular"
          style={{ fontWeight: "600" }}
        >
          You&Me
        </h1>
        <p className="text-white mt-8 text-sm md:text-md max-w-xs imprima-regular">
          Write your diary, voice your thoughts, and bond with people around the
          world.
        </p>
      </div>

      {/* App Store Buttons and Coming Soon Text */}
      <div className="flex flex-col md:flex-row w-[90%] md:w-[80%] space-y-4 md:space-y-0 my-8 justify-evenly items-center">
        <Link
          to="https://play.google.com/store/apps/details?id=com.younme.younme"
          target="blank"
        >
          <div className="bg-black p-1 rounded-md flex items-center justify-center min-w-[190px]">
            <img
              src="/google-play.png"
              alt="Get it on Google Play"
              className="h-12 rounded-md"
            />
          </div>
        </Link>
        <p className="text-white imprima-regular font-light text-xl text-center md:text-left">
          Coming soon on the web.
        </p>
        <Link
          to="https://apps.apple.com/us/app/you-me-social/id6737747331"
          target="blank"
        >
          <div className="bg-black p-1 rounded-md flex items-center justify-center min-w-[190px]">
            <img
              src="/app_store.png"
              alt="Download on the App Store"
              className="h-12 rounded-md"
            />
          </div>
        </Link>
      </div>

      {/* Footer */}
      <footer className="mt-10 text-white text-sm flex flex-col md:flex-row space-x-0 space-y-4 md:space-x-12 md:space-y-0 justify-around w-full px-6 inter-regular items-start mb-2">
        <p className="font-light text-center md:text-left inter-regular">
          Copyright © {currentYear} - All Rights Reserved.
        </p>
        <div className="flex items-center font-light space-x-4 md:space-x-10 inter-regular">
          <Link to="/user-agreement?tab=terms" className="hover:underline">
            Terms of Service
          </Link>
          <Link to="/user-agreement?tab=privacy" className="hover:underline">
            Privacy Policy
          </Link>
        </div>

        {/* Contact Us */}
        <div className="flex items-start font-light mt-2 md:mt-0 inter-regular">
          <p className="inline text-center md:text-left">Contact us: </p>
          <div className="flex flex-col ml-2 space-y-1 text-[#2B337D]">
            <p>
              <a href="mailto:user-care@younme.app" className="hover:underline">
                user-care@younme.app
              </a>
            </p>
            <p>
              <a href="mailto:enquiries@younme.app" className="hover:underline">
                enquiries@younme.app
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
